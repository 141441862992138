export const environment = {
  production: false,
  CLIENT_ID:
    "7d18a423535d3f5a6d7eea4517b7e73ezcorec3b0de7e0d94b2025d835c9920dfe63",
  ENV: "prd",
  BASE_URL_CORE4YOU: "https://core4u-apim.tech4u.services/v1",
  BASE_URL: "https://api.candleconsultas.com.br/api",
  BASE_URL_SOCKET: "https://api.candleconsultas.com.br/",
  // BASE_URL: " http://127.0.0.1:8081/api",
  //BASE_URL: "http://localhost:3000/api",
  //BASE_URL_SOCKET: "http://localhost:3000",
  BASE_URL_CEP: "https://viacep.com.br/ws",
  BIRO_URL:
    "https://biros-creditos.tech4u.services/user-registration/consult-status-user",
  recaptchaKey: "6LdMDKQnAAAAACTeuDrxNivHTEDJeu8UwtfbFtG0",
  // Tokens Sistemas
  TOKEN_DIAGNOSTICO_PREMIUM: "13uygt5rsf0y7ikzux2j8vl5u05wck0c",
};
